let cdnjs = {
    "touchswipe": "https://cdnjs.cloudflare.com/ajax/libs/jquery.touchswipe/1.6.18/jquery.touchSwipe.min.js",
    "headroom": "https://cdnjs.cloudflare.com/ajax/libs/headroom/0.9.4/headroom.min.js",
    "lightgallery": "https://cdnjs.cloudflare.com/ajax/libs/lightgallery/1.6.8/js/lightgallery-all.min.js",
    "picturefill": "https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.3/picturefill.min.js",
    "rellax": "https://cdnjs.cloudflare.com/ajax/libs/rellax/1.6.0/rellax.min.js",
    "datepicker_lang": "https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/i18n/datepicker.cs.min.js",
    "masonry": "https://cdnjs.cloudflare.com/ajax/libs/masonry/4.2.1/masonry.pkgd.min.js",
    "exitbanner": "https://packages.newlogic.cz/newlogic-exitbanner/1.0.2/js/newlogic-exitbanner.min.js",
    "scrollbanner": "https://packages.newlogic.cz/newlogic-scrollbanner/1.0.3/js/newlogic-scrollbanner.min.js",
};
